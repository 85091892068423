<template>
    <v-container class="main-header py-2" :class="{'isCheckout': isCheckout}">
        <v-layout align-center>
            <a href="https://zoopraha.cz">
                <img :src="require('../../assets/logo.png')" class="custom-logo" :class="{'isCheckout': isCheckout}" itemprop="logo" v-if="$vuetify.breakpoint.smAndUp && !isCheckout" />
                <img v-else :src="require('../../assets/logo-mobile.png')" class="custom-logo" />
            </a>
            <v-spacer></v-spacer>
            <router-link  to="/">
                <img class="custom-logo-additional" :src="logo" v-if="$vuetify.breakpoint.mdAndUp && !isCheckout" />
            </router-link>
            <router-link  style="text-decoration: none; color: inherit;" to="/">
                <div class="black--text font-weight-bold ml-3 display-1" v-if="$vuetify.breakpoint.mdAndUp && !isCheckout">
                   STRAVENKY<br />PRO ZVÍŘATA
                </div>
            </router-link>
            <v-spacer></v-spacer>
            <div @click="drawer = true" v-if="!isCheckout">
                <v-badge
                        :content="itemsInCart"
                        :value="itemsInCart"
                        color="primary"
                        overlap
                >
                    <img class="cart-image" :src="cart" :class="{mobileCart: $vuetify.breakpoint.xsOnly}" />
                </v-badge>
            </div>
        </v-layout>

        <v-navigation-drawer
                temporary
                v-model="drawer"
                :right="true"
                fixed
                width="340"
        >
            <cart-overlay @drawerClose="drawer = false" />
        </v-navigation-drawer>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import CartOverlay from '../Cart/CartOverlay'

    export default {
        name: 'MainHeader',
        props: ['isCheckout'],
        data () {
            return {
                drawer: false,
                logo: window.logo,
              cart: window.cart
            }
        },
        components: {
            CartOverlay
        },
        computed: {
            ...mapGetters([
                'cartProducts'
            ]),
            itemsInCart() {
                let cart = this.cartProducts;
                if (!cart[0]) return null;
                return cart.reduce((accum, item) => accum + item.qty, 0)
            }
        }
    }
</script>

<style lang="scss">
    .main-header {
            .v-badge__badge {
                color: #000000;
            }
        .cart-image {
            cursor: pointer;
        }
        .mobileCart {
            max-width: 80px;
        }

        &.isCheckout {
            padding-top: 0;
            padding-bottom: 0;
        }
        .menu-item {
            a {
                padding: 0 1em;
                font-size: 16px;
                color: #FFFFFF !important;
                text-decoration: none;
                text-transform: uppercase;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        .custom-logo {
            margin-top: 5px;
            max-width: 120px;
            &.isCheckout {
                max-width: 120px;
            }
        }
        .custom-logo-additional {
            max-width: 90px;
        }
        .menu-icon-link {
            border-radius: 7px !important;
        }
        .v-list, .v-menu__content {
            border-radius: 0 !important;
        }
        .v-menu__content {
            max-width: 100% !important;
            width: 100%;
            left: 0 !important;
        }
        .v-chip {
            .v-chip__content {
                cursor: pointer !important;
            }
        }
        .opening-hours {
            strong {
                display: block;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
</style>
